@import "@design";

.week-header {
}
.header-cell {
  font-size: 0.9em;
  font-weight: bold;
  background-color: white;
  border-width: 1px;
  &:not(:last-child) {
    border-right-width: 0;
  }

  &:not(.crew-header) {
    padding: 0;
  }

  .header-cell-link {
    padding: 10px 10px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &:link,
    &:visited {
      color: $gray-dark;
    }
    &:hover {
      background-color: darken(white, 8%);
    }

    @media print {
      font-size: 0.8em;
    }
  }

  &.weekend-header {
    background-color: lighten(black, 85%);
    border-color: lighten(black, 75%);
  }
  &.today {
    font-weight: bold;
    border-bottom-color: #d32f2f;
    a.header-cell-link {
      &:link,
      &:visited {
        color: #d32f2f;
      }
    }
  }
  &.is-the-past {
    $val: 50%;

    background-color: lighten(black, $val);
    border-color: lighten(black, $val - 10);
    .header-cell-link {
      &:link,
      &:visited {
        color: darken(white, 10);
      }
      &:hover {
        background-color: lighten(black, ($val - 10));
      }
    }

    &.weekend-header {
      background-color: lighten(black, $val - 7);
      border-color: lighten(black, $val - 15);
      .header-cell-link {
        &:hover {
          background-color: lighten(black, ($val - 18));
        }
      }
    }
  }

  &.key-cell {
    display: flex;
    align-content: center;
    padding: 0 10px;
    font-size: 1.1rem;
    color: white;
    background-color: #9e9e9e;
    border-color: #9e9e9e;
    max-width: 80px;
  }
  &.last-cell {
    border-right: 1px solid lighten(black, 75%);
  }
}
.print-mode .header-cell {
  $w: 100% / 15;

  flex-basis: $w;
}
